import * as S from './styles'

const Sustainability = () => {
  const handleClickButton = (to: string) => window.open(to, '_blank')

  return (
    <S.Container>
      <S.Title>SUSTENTABILIDADE</S.Title>
      <p>Conheça nossas ações de sustentabilidade parceiras do projeto.</p>
      <S.ActionContainer>
        <img src="/certificate.png" alt="" />

        <div>
          <span>CARBONO NEUTRO</span>
          <p>
            O nosso compromisso com a sustentabilidade ganhou um reforço: a Moss, climate tech que está democratizando o
            acesso à soluções ambientais, é a nossa parceria na neutralização das emissões de carbono dos NFTs desta
            coleção. Tudo isso em linha com os Compromissos para o Futuro do Grupo Boticário disponíveis na plataforma.
          </p>
          <S.Button onClick={() => handleClickButton('https://www.grupoboticario.com.br/nossos-compromissos/')}>
            CLIQUE AQUI PARA SABER MAIS
          </S.Button>
        </div>
      </S.ActionContainer>
      <S.ActionContainer className="reverse">
        <img src="/riacho.png" alt="" />

        <div>
          <span>Movimento Viva Água Miringuava</span>
          <p>
            O valor arrecadado com a venda de NFTs será doado para o Fundo Viva Água. A iniciativa tem como compromissos
            a conservação da natureza, a segurança hídrica, a adaptação às mudanças climáticas e o empreendedorismo
            sustentável. Entre as ações em andamento está a restauração de áreas naturais que, entre outros benefícios,
            promove a compensação de carbono em São José dos Pinhais (PR). O plantio de 1.600 árvores (1,3 hectare)
            realizado esse ano foi apenas a primeira parte, em setembro deve ser realizado o plantio em mais 10
            hectares.
          </p>
          <S.Button
            onClick={() =>
              handleClickButton(
                'https://www.fundacaogrupoboticario.org.br/pt/engajamento/Paginas/Inicial.aspx?aba=4#abas'
              )
            }
          >
            CLIQUE AQUI PARA SABER MAIS
          </S.Button>
        </div>
      </S.ActionContainer>
    </S.Container>
  )
}

export default Sustainability
