import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

interface WrapperProps {
  children?: React.ReactNode
}

export const Wrapper: React.FC<WrapperProps> = ({ children }) => {
  return <Container>{children}</Container>
}
