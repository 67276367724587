import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Cousine';
    src: url('/fonts/Cousine-Regular.ttf');
  }
  @font-face {
    font-family: 'Cousine-Bold';
    src: url('/fonts/Cousine-Bold.ttf');
  }
  @font-face {
    font-family: 'Cousine-BoldItalic';
    src: url('/fonts/Cousine-BoldItalic.ttf');
  }
  @font-face {
    font-family: 'Cousine-Italic';
    src: url('/fonts/Cousine-Italic.ttf');
  }
  button {
    font-family: 'Anton', sans-serif;
  }
  * {
    border: 0;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.fonts.default}, sans-serif;
    color: ${({ theme }) => theme.colors.default};
  }
  body {
    background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  }
`

export default GlobalStyle
