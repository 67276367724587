import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 2rem;

  background-image: url('/background-blur.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  span {
    font-family: 'Cousine';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    max-width: 50%;
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
  }
  @media screen and (max-width: 920px) {
    span {
      max-width: 100%;
    }
    padding: 2rem 1rem;
  }
`

export const Title = styled.h2`
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  margin: 1rem 0;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: ${({ theme }) => theme.colors.secondaryLight};
`
export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 75%;
  flex-wrap: wrap;
  padding: 0 1rem;

  @media screen and (max-width: 920px) {
    flex-direction: column;
    max-width: 100vw;
  }

  .video-iframe {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0.5rem;

    span {
      font-family: 'Cousine';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;

      text-align: center;
      letter-spacing: 1px;
      text-align: center;

      color: #ffffff;
    }
    iframe {
      width: 18.75rem;
      height: 11.7569rem;
    }
  }
`
