import * as S from './styles'

const Nav = () => {
  return (
    <S.Container>
      <img src="/logo.svg" alt="Logo da campanha NFT Boca Única" />
    </S.Container>
  )
}

export default Nav
