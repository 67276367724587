import styled from 'styled-components'

export const Container = styled.section`
  background-image: url('/background-2.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: linear-gradient(180deg, #2b0042 0%, rgba(71, 11, 103, 0) 60.96%);

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
      max-width: fit-content;
    }
    span {
      color: #fff;
    }
    strong {
      color: #fff;
    }
    iframe {
      width: 32rem;
      height: 16rem;
      margin: 1rem;
    }
    p {
      max-width: fit-content;
      color: #ffffff;
      padding: 1rem;
    }
  }
  .video-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      text-align: center;
      width: 100%;
      margin: 1rem 0;
      text-align: center;
    }
  }

  @media screen and (max-width: 920px) {
    flex-direction: column;
    padding: 2rem;
    div {
      p {
        color: #ffffff;
        padding: 0;
        margin-bottom: 1rem;
        width: 100%;
      }
      img {
        max-width: 100%;
      }
      iframe {
        max-width: 100%;
        margin-top: 2rem;
      }
    }
  }
`
