import * as S from './styles'
import StarsIcon from '../../assets/icons/stars.svg'

const Benefits = () => {
  return (
    <S.Container>
      <S.Title>BENEFÍCIOS</S.Title>
      <p>Conheça os benefícios de cada NFT</p>
      <S.ActionContainer>
        <S.CardInfo className="primary">
          <div>
            <span>PACOTE GOLD</span>
            <img src={StarsIcon} alt="" />
          </div>
          <div className="textContent">
            <ul>
              <li>Ganha o NFT da própria boca transformada em obra de arte</li>
              <li>1 pôster físico do seu NFT</li>
              <li>1 arte digital do seu NFT</li>
              <li>Kit contendo os 10 batons heroes de Eudora e Boticário</li>
              <li>Tutoriais exclusivos de make com os experts Lavoisier e Sadi</li>
              <li>Desconto de 50% nos batons Boti e Eudora até Setembro 22 no E-commerce das marcas</li>
            </ul>
          </div>
        </S.CardInfo>
        <S.CardInfo className="secondary">
          <div>
            <span>PACOTE PLATINUM</span>
            <img src={StarsIcon} alt="" />
          </div>
          <div className="textContent">
            <ul>
              <li>Ganha o NFT da própria boca transformada em obra de arte</li>
              <li>1 pôster físico do seu NFT</li>
              <li>1 arte digital do seu NFT</li>
              <li>
                Experiência única de fazer seu próprio batom na fábrica do GB, com despesas pagas Desconto de 50% nos
                batons Boti e Eudora até Setembro 22 no E-comm das marcas
              </li>
              <li>Kit completo com makes Boti e Eudora, contendo 30 itens para boca, olhos e rosto</li>
              <li>Tutoriais exclusivos de make com os experts Lavoisier e Sadi</li>
              <li>Desconto de 50% nos batons Boti e Eudora até Setembro 22 no E-commerce das marcas</li>
            </ul>
          </div>
        </S.CardInfo>
      </S.ActionContainer>
    </S.Container>
  )
}

export default Benefits
