import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 2rem;

  P {
    margin: 1rem 0;
    font-family: 'Cousine';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 152% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #d428bb;
  }
`

export const Title = styled.h2`
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  margin: 1rem 0;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #5b1993;
`

export const ActionContainer = styled.div`
  display: flex;
  .cta {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    align-items: center;
    width: 100%;
  }
  @media screen and (max-width: 920px) {
    flex-direction: column;
    .cta {
      margin: 1rem 0;
    }
  }
`

export const ButtonLabel = styled.span`
  font-family: 'Cousine';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;

  text-align: center;

  color: #000000;
`

export const Link = styled.a`
  font-family: 'Anton';
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 1rem;
  text-align: center;
  width: 18.75rem;
  height: 3.75rem;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.text};
  border-radius: 3.75rem;
  text-decoration: none;
  :hover {
    cursor: pointer;
  }
`
