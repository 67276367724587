export default {
  fonts: {
    default: 'Cousine',
    bold: 'Cousine-Bold',
    boldItalic: 'Cousine-BoldItalic',
    italic: 'Cousine-Italic'
  },
  colors: {
    primary: '#5B3FE0',
    primaryLight: '#485FBB',
    secondary: '#5b1993',
    secondaryLight: '#D428BB',
    succes: '#00D63D',
    danger: '#D60716',
    background: '#F4F5F7',
    backgroundSecondary: '#FFF',
    backgroundContrast: '#470B67',
    default: '#000',
    text: '#FFF'
  }
}
