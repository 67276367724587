import styled from 'styled-components'

export const Container = styled.section`
  background-image: url('/background-cards.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 2rem;
  max-width: 100vw;
  P {
    margin: 1rem 0;
    font-family: 'Cousine';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;

    max-width: 50%;
    text-align: center;

    color: #d428bb;
  }
  @media screen and (max-width: 920px) {
    padding: 0rem 2rem;
    p {
      max-width: 100%;
    }
  }
`

export const Title = styled.h2`
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  margin: 1rem 0;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #5b1993;
`

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .nft-card {
    display: flex;
    flex-direction: column;
    margin: 1rem;

    background-color: #5b1993;
    div {
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-family: 'Anton';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        /* or 117% */

        letter-spacing: 1px;
        text-transform: uppercase;

        color: #ffffff;
      }
      p {
        font-family: 'Cousine';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;

        display: flex;
        align-items: center;
        text-align: right;

        color: #ffffff;
      }
    }
  }

  @media screen and (max-width: 920px) {
    flex-direction: column;
    .nft-card {
      img {
        max-width: 90vw;
      }
    }
  }
`
