import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { Home, Faq, Regulation } from '../screens'

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<Home />}></Route>
        <Route path="/faq" element={<Faq />}></Route>
        <Route path="/regulation" element={<Regulation />}></Route>
      </Routes>
    </BrowserRouter>
  )
}
