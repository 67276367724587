import styled from 'styled-components'
import ButtonBase from '../Button'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 2rem;
  background-color: ${({ theme }) => theme.colors.primary};

  P {
    margin: 1rem 0;
    font-family: 'Cousine';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;

    max-width: 50%;
    text-align: center;

    color: ${({ theme }) => theme.colors.text};
  }
`

export const Title = styled.h2`
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  margin: 1rem 0;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: ${({ theme }) => theme.colors.text};
`

export const Button = styled(ButtonBase)``

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: ${({ className }) => (className === 'reverse' ? 'row-reverse' : 'row')};
  margin: 1rem 0;

  div {
    padding: 2rem;
    background-color: #fff;

    span {
      font-family: 'Anton';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      color: ${({ theme }) => theme.colors.backgroundContrast};
    }
    p {
      font-family: 'Cousine';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      max-width: max-content;
      text-align: justify;
      color: ${({ theme }) => theme.colors.secondary};
    }
  }

  @media screen and (max-width: 920px) {
    flex-direction: column;
  }
`
