import { useNavigate } from 'react-router-dom'
import * as S from './styles'

const FaqSection = () => {
  const navigate = useNavigate()
  const handleClickButton = () => {
    return navigate('/faq')
  }
  return (
    <S.Container id="faq">
      <S.Title>PRECISA DE AJUDA?</S.Title>
      <span>Você comprou um NFT e tem dúvidas? Abaixo temos alguns caminhos de solução.</span>
      <S.ActionContainer>
        <S.FaqItem>
          <span>Foto</span>
          <p className="title">Comprei um NFT, mas não enviei a foto da minha boca</p>
          <p>
            Sem a sua foto não iremos conseguir criar um NFT personalizado para você :/ Durante a campanha, nós enviamos
            um link por email para receber uma foto da sua boca de forma segura.
          </p>
          <p>
            Ação: Verifique o histórico do email que você utilizou para fazer o login na nossa plataforma e busque por
            uma mensagem com o título “Chegou a hora: nos envie sua foto!”. Lá você vai encontrar o link da página para
            fazer o envio da sua foto.
          </p>
        </S.FaqItem>
        <S.FaqItem>
          <span>Carteira</span>
          <p className="title">Eu enviei a foto da minha boca, mas não sei onde está meu NFT</p>
          <p>
            Quando nossa artista nos enviou a arte da sua boca, nós criamos uma carteira provisória para você e enviamos
            seu NFT para lá. No entanto, precisamos que você crie uma carteira pessoal para realizarmos uma
            transferência e você passe a movimentar seu NFT com independência.
          </p>
          <p>
            Ação: Verifique o histórico do email que você utilizou para fazer o login na nossa plataforma e busque por
            uma mensagem com o título “Coleção Boca Única: seu NFT está pronto!”. Lá você vai encontrar as instruções
            sobre como criar sua carteira pessoal e receber seu NFT.
          </p>
        </S.FaqItem>
        <S.FaqItem>
          <span>Utilização</span>
          <p className="title">Estou com o NFT na minha carteira, mas não sei como utilizar</p>
          <p>
            Um NFT pode ser utilizado de diversas formas. As aplicações mais comuns são: exposição na OpenSea, imagem de
            perfil nas redes sociais, aplicações em peças físicas e transferência/venda para outras carteiras. Além da
            arte digital, nós também enviamos para seu endereço um pôster físico que pode ficar exposto como uma obra de
            arte :)
          </p>
          <p>
            Ação: Quando criamos seu NFT, enviamos para você a imagem da arte em formato digital e um pôster físico. Com
            esses itens em mãos você pode expôr seu NFT digital e fisicamente. Além disso, com o código do NFT que está
            na sua carteira, você pode realizar transferências ou aplicar sua arte digital como imagem de perfil nas
            redes sociais que já suportam esse tipo de recurso (como Instagram e Twitter).
          </p>
        </S.FaqItem>
        <S.FaqItem>
          <span>Beneficios</span>
          <p className="title">Recebi meu NFT, mas ainda não recebi os benefícios físicos em casa</p>
          <p>
            Como pessoas de todas as regiões do país compraram nossos NFTs, alguns clientes podem receber seus
            benefícios antes de outros por questões logísticias. Além disso, só conseguimos enviar os benefícios após a
            confirmação do endereço de entrega que é informado durante o processo de envio da foto.
          </p>
          <p>
            Ação: Caso você ainda não tenha nos enviado a foto da sua boca, verifique o histórico do email que você
            utilizou para fazer o login na nossa plataforma e busque por uma mensagem com o título “Chegou a hora: nos
            envie sua foto!”. Lá você vai encontrar o link da página para fazer o envio da sua foto e endereço de
            entrega. Caso você já tenha enviado sua foto, busque no histórico do seu email pela mensagem com título
            “Coleção Boca Única: seus benefícios estão a caminho!”. Lá você vai encontrar a estimativa do prazo de
            entrega dos benefícios para sua região.
          </p>
        </S.FaqItem>
      </S.ActionContainer>
      <S.MoreFaqSection>
        <S.Title>AINDA TEM DÚVIDAS?</S.Title>
        <span>Temos informações complementares na nossa sessão de perguntas frequentes.</span>
        <S.Button onClick={handleClickButton}>ACESSAR A FAQ</S.Button>
      </S.MoreFaqSection>
    </S.Container>
  )
}

export default FaqSection
