import * as S from './styles'

const Info = () => {
  return (
    <S.Container>
      <div className="text-container">
        <p>
          O <strong>Boticário</strong> e <strong>Eudora</strong> se uniram para lançar a primeira coleção de NFTs que
          vai eternizar bocas reais em ilustrações únicas, que concedem benefícios exclusivos.
        </p>

        <img src="/promocional_sabrina_sato.svg" alt="Ilustração da boca de uma mulher com batom preto" />
      </div>
      <div className="video-container">
        <iframe
          src="https://www.youtube.com/embed/641oo8rbSEw"
          title="NFT Dia do Batom - O Boticário + Eudora"
          allow="accelerometer; autoplay; playsinline; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
        <br />
        <span>Assista o vídeo e saiba mais</span>
      </div>
    </S.Container>
  )
}

export default Info
