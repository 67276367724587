import styled from 'styled-components'

export const Container = styled.section`
  background-image: url('/background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 2rem;

  img {
    max-width: 500px;
    margin: 1rem;
  }

  @media screen and (max-width: 920px) {
    flex-direction: column;

    img {
      max-width: 100%;
    }
  }
`
