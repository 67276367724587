import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.secondary};
  h1 {
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 80px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.text};
  }
  #return-container {
    margin: 1rem;
    display: flex;
    justify-content: center;
    max-height: 2.5rem;
    :hover {
      cursor: pointer;
    }
    span {
      margin-left: 1rem;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      color: ${({ theme }) => theme.colors.text};
    }
  }

  #card-container {
    margin-top: 3rem;
    margin-left: 2rem;
    flex: 1;
  }

  @media screen and (max-width: 920px) {
    flex-direction: column;
    #card-container {
      margin-top: 0rem;
      margin-left: 0rem;
      padding: 1rem;
    }
    #return-container {
      margin-top: 1rem;
      justify-content: flex-start;

      span {
        color: ${({ theme }) => theme.colors.text};
      }
    }
  }
`

export const MessageCard = styled.div`
  background-color: #fff;
  display: flex;
  border-radius: 0.625rem;
  flex-direction: column;
  align-items: space-around;
  padding: 2rem 2rem 1rem 2rem;
  margin: 1rem 0;
  max-width: 90%;
  @media screen and (max-width: 920px) {
    max-width: 100%;
  }
  .title {
    font-family: ${({ theme }) => theme.fonts.bold};
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.default};
  }
  .text {
    margin: 1rem 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
  #toggleCard {
    display: flex;
    justify-content: space-between;
    :hover {
      cursor: pointer;
    }
  }
  transition: height 10ms;

  .close {
    max-height: 0;
    transition: max-height 0.25s ease-in;
    display: none;
  }
  max-height: 500px;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
`
