import styled from 'styled-components'

export const Container = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 3rem;
  background-color: ${({ theme }) => theme.colors.secondary};
  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 33%;
    margin: 0 0.5rem;

    a {
      color: #fff;
      text-decoration: none;
      margin: 1rem 0;
    }
    span {
      color: #fff;
      font-size: 11px;
    }
    img {
      max-width: 190px;
    }
  }
  @media screen and (max-width: 920px) {
    flex-direction: column;
    div {
    }
  }
`
