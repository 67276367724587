import * as S from './styles'
import GBLogo from '../../assets/pode_confiar_gb.svg'

const Footer = () => {
  return (
    <S.Container>
      <div>
        <img src={GBLogo} alt="Logo do Grupo Boticário em branco com texto superior dizendo 'Pode Confiar'" />
        <a href="https://www.grupoboticario.com.br/">https://www.grupoboticario.com.br/</a>
        <span>
          Copyright © O Boticário e Eudora 2022 <br />
          Produto oficial licenciado, todos os direitos reservados.
        </span>
        <a href="/regulation">Acesse o regulamento.</a>
      </div>
    </S.Container>
  )
}

export default Footer
