import * as S from './styles'
import Logo from '../../assets/hero/logo.png'
import MouthModelImage from '../../assets/hero/mouthModel.png'

const Hero = () => {
  return (
    <S.Container>
      <img src={Logo} className="logo" alt="Logo da campanha do dia do batom escrita em branco" />
      <img
        src={MouthModelImage}
        className="mouth"
        alt="Boca de uma modelo negra ilustrada utilizando um batom na cor rosa com as marcas de 'O Boticário' e 'Eudora' na parte superior"
      />
    </S.Container>
  )
}

export default Hero
