import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 2rem;

  P {
    margin: 1rem 0;
    font-family: 'Cousine';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;

    max-width: 50%;
    text-align: center;

    color: #d428bb;
  }
  @media screen and (max-width: 920px) {
    padding: 2rem 1rem;
  }
`

export const Title = styled.h2`
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  margin: 1rem 0;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: ${({ theme }) => theme.colors.secondary};
`

export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 920px) {
    flex-direction: column;
    max-width: 100vw;
  }
`

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  width: 100%;
  max-width: 31.8125rem;

  div {
    background-color: ${({ className, theme }) =>
      className === 'secondary' ? theme.colors.primary : theme.colors.secondaryLight};

    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-family: 'Anton';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      margin-right: 0.75rem;
      letter-spacing: 1px;
      text-transform: uppercase;

      color: ${({ theme }) => theme.colors.text};
    }
    p {
      font-family: 'Cousine';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;

      display: flex;
      align-items: center;
      text-align: right;

      color: ${({ theme }) => theme.colors.text};
    }
  }
  .textContent {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    background-color: ${({ theme }) => theme.colors.backgroundContrast};
    padding: 1rem 2rem;

    height: 29.5rem;

    ul {
      li {
        color: ${({ theme }) => theme.colors.text};
        font-family: 'Cousine';
        font-style: normal;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1.25rem;
        margin: 0.75rem 0;
      }
    }
  }
  @media screen and (max-width: 920px) {
    .textContent {
      height: auto;
    }
  }
`
