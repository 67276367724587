import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import samplePdf from '../assets/regulamento_nft_boca_unica.pdf'

const Regulation = () => {
  const navigate = useNavigate()
  function onLoadDocument() {
    window.open(samplePdf)
    return navigate('/')
  }

  useEffect(() => {
    onLoadDocument()
  }, [])

  return <div></div>
}

export default Regulation
