import styled from 'styled-components'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode
}

const ButtonCompnent = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 1rem;
  text-align: center;
  width: 18.75rem;
  height: 3.75rem;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.text};
  border-radius: 3.75rem;
  :hover {
    cursor: pointer;
  }
`

const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  return <ButtonCompnent {...props}>{children}</ButtonCompnent>
}

export default Button
