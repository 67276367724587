import * as S from './styles'
import Button from '../Button'

const Campaign = () => {
  const handleClickButton = (to: string) => {
    return window.open(to, '_blank')
  }
  return (
    <S.Container>
      <S.Title>NOSSA AÇÃO JÁ SE ENCERROU!</S.Title>
      <p>O que você está procurando?</p>
      <S.ActionContainer>
        <div className="cta">
          <S.ButtonLabel>Quero saber mais sobre a coleção</S.ButtonLabel>
          <Button onClick={() => handleClickButton('https://opensea.io/collection/boticario-nft')}>
            COLEÇÃO NA OPEN SEA
          </Button>
        </div>
        <div className="cta">
          <S.ButtonLabel>Comprei um NFT e preciso de ajuda</S.ButtonLabel>
          <S.Link href="#faq">SAIBA MAIS</S.Link>
        </div>
      </S.ActionContainer>
    </S.Container>
  )
}

export default Campaign
