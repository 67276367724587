import * as S from './styles'

const AboutMore = () => {
  return (
    <S.Container>
      <S.Title>CONHEÇA MAIS SOBRE NFTs</S.Title>
      <span>
        Está com dúvidas? Calma que a gente te ajuda! Separamos alguns vídeos para você conhecer um pouco mais sobre o
        universo dos NFTs.
      </span>
      <S.ActionContainer>
        <div className="video-iframe">
          <iframe
            src="https://www.youtube.com/embed/VTcp451h2t8"
            frameBorder="0"
            allow="accelerometer; autoplay; playsinline; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
          <span>O que é um NFT?</span>
        </div>
        <div className="video-iframe">
          <iframe
            src="https://www.youtube.com/embed/2X8v356FqWc"
            frameBorder="0"
            allow="accelerometer; autoplay; playsinline; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
          <span>Como adiquirir um NFT?</span>
        </div>
        <div className="video-iframe">
          <iframe
            src="https://www.youtube.com/embed/2X8v356FqWc"
            frameBorder="0"
            allow="accelerometer; autoplay; playsinline; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
          <span>O que é uma Wallet?</span>
        </div>
        <div className="video-iframe">
          <iframe
            src="https://www.youtube.com/embed/wqInGeu8-BM"
            frameBorder="0"
            allow="accelerometer; autoplay; playsinline; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
          <span>Os NFTs são seguros?</span>
        </div>
        <div className="video-iframe">
          <iframe
            src="https://www.youtube.com/embed/rRbf_ligFZU"
            frameBorder="0"
            allow="accelerometer; autoplay; playsinline; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
          <span>Quem vai criar a arte da minha boca?</span>
        </div>
      </S.ActionContainer>
    </S.Container>
  )
}

export default AboutMore
