import { Wrapper } from '../components/Wrapper'
import { Nav, Hero, Campaign, Info, Nft, Benefits, Sustainability, FaqSection, AboutMore, Footer } from '../components'

function Home() {
  return (
    <Wrapper>
      <Nav />
      <Hero />
      <Campaign />
      <Info />
      <Nft />
      <Benefits />
      <Sustainability />
      <FaqSection />
      <AboutMore />
      <Footer />
    </Wrapper>
  )
}

export default Home
