import * as S from './styles'

import BocaMulherPreta from '../../assets/boca-1.png'
import BocaMulherBranca from '../../assets/boca-2.png'

const Nft = () => {
  return (
    <S.Container>
      <S.Title>NFT</S.Title>
      <p>
        Dois pacotes foram vendidos com o objetivo de transformar bocas e histórias em ilustrações artísticas para
        eternizá-las digitalmente
      </p>
      <S.ActionContainer>
        <div className="nft-card">
          <img
            src={BocaMulherBranca}
            alt="Imagem ilustrada de uma boca de uma pessoa branca com cabelos loiros, utilizando um batom preto"
          />
          <div>
            <span>PACOTE GOLD</span>
            <p>
              unidades: 50 <br /> disponiveis: 0
            </p>
          </div>
        </div>
        <div className="nft-card">
          <img
            src={BocaMulherPreta}
            alt="Imagem ilustrada de uma boca de uma pessoa preta com cabelos pretos, utilizando um batom rosa"
          />
          <div>
            <span>PACOTE PLATINUM</span>
            <p>
              unidades: 10 <br /> disponiveis: 0
            </p>
          </div>
        </div>
      </S.ActionContainer>
    </S.Container>
  )
}

export default Nft
