import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import * as S from './styles'

import arrowUp from '../../assets/arrow-up.svg'
import arrowDown from '../../assets/arrow-down.svg'
import MESSAGE_FAQ from './MESSAGE_FAQ'

const FaqCards = () => {
  const [indexCardOpened, setIndexCardOpened] = useState<null | number>(null)
  const navigate = useNavigate()

  const setCardOpened = (id: number) => {
    if (indexCardOpened === id) {
      setIndexCardOpened(null)
    } else {
      setIndexCardOpened(id)
    }
  }

  return (
    <S.Container>
      <div onClick={() => navigate('/')} id="return-container">
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 1024 1024"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 0 0 0 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path>
        </svg>
        <span>voltar</span>
      </div>
      <div id="card-container">
        <h1>FAQ</h1>
        {MESSAGE_FAQ.map((item) => {
          return (
            <S.MessageCard key={item.id.toString()}>
              <div onClick={() => setCardOpened(item.id)} id="toggleCard">
                <p className="title">{item.title}</p>
                <img src={indexCardOpened === item.id ? arrowDown : arrowUp} />
              </div>
              <p className={indexCardOpened === item.id ? 'text' : 'text close'}>{item.message}</p>
            </S.MessageCard>
          )
        })}
      </div>
    </S.Container>
  )
}

export default FaqCards
