export default [
  {
    id: 0,
    title: 'O que é o Dia do Batom?',
    message: `O Dia do Batom, 29 de julho, é uma data escolhida por Eudora, há cinco anos, para celebrar esse item de beleza tão icônico. Pioneira nas ações atreladas ao Dia do Batom, para o dia “mais Eudora do ano”, a marca programou diversos outros conteúdos para comemorar a data nas redes sociais, bem como estratégias com influenciadores e seus experts de beleza.`
  },
  {
    id: 1,
    title: 'O que é NFT?',
    message: `NFT significa “Token não-fungível”, um certificado digital de propriedade de um item físico ou digital registrado na blockchain, uma tecnologia que funciona mais ou menos como um livro-razão de cartório. É essa tecnologia que garante toda segurança e autenticidade do processo.`
  },
  {
    id: 2,
    title: 'Como adiquirir um NFT?',
    message: `Para comprar um NFT é bem simples, basta visitar um marketplace de NFTs da sua escolha, vincular sua carteira digital, escolher seu NFT e efetuar o pagamento. Vale lembrar que cada plataforma possui suas próprias formas de pagamento, essas lojas em grande maioria optam por usar criptomoedas como forma principal de pagamento.`
  },
  {
    id: 3,
    title: 'Como adiquirir seu NFT da Boca Única?',
    message: `Para conseguir um dos nossos NFTs é ainda mais simples, pois neste caso você pode fazer tudo pelo nosso site. Basta acessar o site nft.diadobatom.com.br, se cadastrar e escolher qual tipo de NFT deseja comprar. Temos 2 opções disponíveis: NFTs Platinum, que serão vendidos por meio de um leilão, e NFTs Gold, que serão vendidos pelo valor de R$149,00. Lembrando que cada um deles te concede benefícios diferentes e exclusivos. A compra pode ser realizada de maneira muito simples também, uma vez que é possível escolher qualquer um dos tipos de pagamento: PIX, cartão de crédito ou criptomoeda USDC. Após a compra, seu NFT estará na sua aba de usuário e poderá ser transferido para sua carteira pessoal, caso você já tenha ou essa pode ser uma oportunidade para você criar sua própria carteira em uma plataforma de sua preferência, como Metamask. Vale lembrar que o processo será todo personalizado. Por isso, você receberá um email solicitando que siga o passo a passo indicado para enviar a foto da sua boca. Com sua foto vamos criar uma arte exclusiva que será transformada em um NFT incrível da sua boca. E por fim, você será avisado(a) quando tudo tiver prontinho.`
  },
  {
    id: 4,
    title: 'O que é uma carteira (wallet) e como criar uma?',
    message: `As Wallets são apps usados para armazenar e gerir ativos digitais, como os NFTs e as criptomoedas. Na ponta do lápis, as wallets são semelhantes a contas bancárias. Basicamente, para comprar um NFT você precisa de uma wallet para armazená-lo. Com uma Wallet você consegue realizar transações de “pessoa para pessoa" de forma segura e descentralizada através da blockchain.`
  },
  {
    id: 5,
    title: 'Por que as marcas escolheram o Dia do Batom para fazer uso de NFTs?',
    message: `Somos marcas de beleza, valorizamos a expressão, a autoestima como identidade e vimos no conceito do NFT de ser único uma forma de mostrar como todas as bocas são únicas utilizando a força e relevância que temos na data. A ação é uma grande oportunidade para aproveitarmos as sinergias entre as duas marcas e, também, para potencializarmos iniciativas que conectem pilares estratégicos para o Grupo Boticário, da qual ambas fazem parte, trazendo cada vez mais tecnologia por meio de inovação e valorização da autoestima junto aos nossos batons e aos nossos consumidores. Essa parceria demonstra nosso foco em proporcionar experiências cada vez mais exclusivas à nossa rede.`
  },
  {
    id: 6,
    title: 'Como adiquirir seu NFT da Boca Única?',
    message: `Para conseguir um dos nossos NFTs é ainda mais simples, pois neste caso você pode fazer tudo pelo nosso site. Basta acessar o site nft.diadobatom.com.br, se cadastrar e escolher qual tipo de NFT deseja comprar. Temos 2 opções disponíveis: NFTs Platinum, que serão vendidos por meio de um leilão, e NFTs Gold, que serão vendidos pelo valor de R$149,00. Lembrando que cada um deles te concede benefícios diferentes e exclusivos. A compra pode ser realizada de maneira muito simples também, uma vez que é possível escolher qualquer um dos tipos de pagamento: PIX, cartão de crédito ou criptomoeda USDC. Após a compra, seu NFT estará na sua aba de usuário e poderá ser transferido para sua carteira pessoal, caso você já tenha ou essa pode ser uma oportunidade para você criar sua própria carteira em uma plataforma de sua preferência, como Metamask. Vale lembrar que o processo será todo personalizado. Por isso, você receberá um email solicitando que siga o passo a passo indicado para enviar a foto da sua boca. Com sua foto vamos criar uma arte exclusiva que será transformada em um NFT incrível da sua boca. E por fim, você será avisado(a) quando tudo tiver prontinho.`
  },
  {
    id: 7,
    title: 'Mais dúvidas??',
    message: `Caso precise de mais informações ou tenha dúvidas sobre o processo, faça sua solicitação via email: nftbocaunica@grupoboticario.com.br`
  }
]
