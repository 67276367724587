import styled from 'styled-components'
import ButtonBase from '../Button'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6rem 2rem;

  span {
    font-family: 'Cousine';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;

    color: #000000;
  }
  @media screen and (max-width: 920px) {
    padding: 2rem 1rem;
  }
`

export const Title = styled.h2`
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  margin: 1rem 0;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: ${({ theme }) => theme.colors.secondary};
`

export const FaqItem = styled.div`
  margin: 2rem 0;

  span {
    font-family: 'Cousine';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */

    color: #d428bb;
  }
  .title {
    font-family: 'Cousine';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    /* identical to box height, or 117% */
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    color: #000000;
  }
  p {
    font-family: 'Cousine';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* or 133% */

    color: #5b1993;
  }
  p ~ p {
    margin-bottom: 1rem;
  }
`
export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 0 1rem;

  @media screen and (max-width: 920px) {
    flex-direction: column;
    max-width: 100vw;
  }

  ${FaqItem}::before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    left: -10px;
    border-radius: 50%;
    background: #d428bb;
    margin: 0 auto;
  }
`
export const MoreFaqSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  span {
    max-width: 50%;
    text-align: center;
  }
  @media screen and (max-width: 920px) {
    span {
      max-width: 100%;
    }
  }
`

export const Button = styled(ButtonBase)`
  margin: 2rem 0;
`
