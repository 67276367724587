import styled from 'styled-components'

export const Container = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  width: 100%;
  max-width: 100vw;
  padding: 1rem;
  margin: 0;
  border: 0;
`
