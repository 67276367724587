import { Nav, Faqcards, Footer } from '../components'

function Home() {
  return (
    <>
      <Nav />
      <Faqcards />
      <Footer />
    </>
  )
}

export default Home
